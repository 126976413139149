<template>
  <div class="pl-3">
    <v-container class="container--fluid rounded-lg bg-white px-0 app-height-global mt-3">
      <v-row justify="center">
        <v-col
          cols="12"
          sm="12"
        >
          <v-progress-linear
            color="#0C2FF3"
            background-color="#F2F4F7"
            value="100"
          />
        </v-col>
        <v-col
          cols="12"
          sm="12"
          class="back-btn-container px-8"
        >
          <div class="back-btn">
            <router-link :to="{ name: 'ColumnMapping' }">
              <v-icon>mdi-chevron-left</v-icon> {{ $t('backToColumnMapping') }}
            </router-link>
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          class="block main-area"
        >
          <h2>{{ $t('importPreview') }}</h2>
          <preview-item
            v-for="(item, index) in importedTitle"
            :key="index"
            class="my-2"
            :item-label="item"
            :item-content="importedData[0][index]"
          />
        </v-col>
        <v-col
          cols="12"
          sm="12"
          class="d-flex justify-end pl-6 pr-10"
        >
          <v-btn
            class="text-capitalize btn-theme"
            depressed
            color="primary"
            height="38px"
            width="141px"
            @click="importAllCases"
          >
            {{ $t('importAll') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import PreviewItem from '@/views/Tests/Case/Components/PreviewItem.vue'
import makeCasesService from '@/services/api/case';
import { createNamespacedHelpers } from 'vuex';

const { mapState } = createNamespacedHelpers('user');
let caseService;

export default {
  components: {
    PreviewItem,
  },
  props:['data'],
  data(){
    return {
      importedHeader: '',
      importedData: '',
      dataArray: [],
    }
  },
  computed: {
    ...mapState(['currentAccount']),
  },
  created() {
    caseService = makeCasesService(this.$api);
    this.dataArray = this.$route.params.data;
    this.importedTitle = this.dataArray.title;
    this.importedData = this.dataArray.data;
  },
  methods: {
    async importAllCases() {
      const handle = this.currentAccount.handle;
      let tagIndex, priorIndex, nameIndex, externalIndex, sourceIndex = 0;
      tagIndex = this.importedTitle.indexOf('tag');
      priorIndex = this.importedTitle.indexOf('priority');
      nameIndex = this.importedTitle.indexOf('name');
      externalIndex = this.importedTitle.indexOf('externalId');
      sourceIndex = this.importedTitle.indexOf('source');
      let payload = [];
      for (let i = 0; i < this.importedData.length; i++){
        if (this.importedData[i]) {
          let customField = {
            tag: this.importedData[i][tagIndex],
            priority: this.importedData[i][priorIndex],
            template: "",
            steps: "",
            sharedSteps: ""
          };
          let data = {
            name: this.importedData[i][nameIndex],
            externalId: this.importedData[i][externalIndex],
            source: this.importedData[i][sourceIndex],
            customFields: customField,
            parentId: this.$store.state.folder.selectedFolderUID || ''
          };
          payload.push(data);
        }
      }
      try {
        const response = await caseService.createTestCase(
          handle,
          this.$route.params.key,
          payload
        );
        if (response.status == 200) {
          this.$router.push({ name: 'Cases' })
        } else {
          console.log(response.error);
        }
      } catch(err) {
        console.error("Error :", err);
      }
    }
  },
}
</script>
<style>
h2, h4, h5{
  text-align: left;
}
h2{
  font-size: 24px !important;
  font-weight: 700;
  color: #18181A;
}
h5{
  font-size: 14px !important;
  color: #0C111D;
  font-weight: 500;
}
button {
  font-size: 14px;
}
.v-align-start {
  align-items: flex-start !important;
}
.font-inter{
  font-family: Inter;
}
.card{
  border-radius: 8px;
}
.block{
  display: block;
}
.main-area{
  max-width: 438px;
  margin-left: auto;
  margin-right: auto;
}
.round-8{
  border-radius: 8px;
}
.relative{
  position: relative;
}
.absolute{
  position: absolute;
}
.bottom-0{
  bottom: 0;
}
.justify-end{
  justify-content: flex-end;
}
.text-white{
  color: white !important;
}
.round-6{
  border-radius: 6px;
}
.flex{
  display: flex;
}
</style>
